import axios from 'axios';
// import { ErrorBag } from 'vee-validate';
// const errorBag = new ErrorBag();

const apiClient = axios.create({
  baseURL: import.meta.env.APP_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

apiClient.interceptors.request.use(
  config => {
    // errorBag.clear();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  response => {

    // conversion api - event to pixel
    if (response.data.events && response.data.events.length > 0) {
      response.data.events.forEach((element) => {
        fbq(`${element.eventType}`, `${element.eventName}`, element.customData, element.eventData);
      })
    }

    return response;
  },
  error => {
    if (error.response.status === 422) {
      if (error.response.data.errors){
        for(let key in error.response.data.errors){
          // errorBag.errors.add({
          //     field: key,
          //     msg: error.response.data.errors[key]
          // })
        }
      }
    } else {
      console.error(error)
    }

    return Promise.reject(error);
  })

export default apiClient
