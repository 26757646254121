<script setup>
  import { storeToRefs } from 'pinia'
  import { computed, ref } from 'vue'
  import { event } from 'vue-gtag'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

  import AgreementField from '@/components/Forms/AgreementField.vue'
  import CertificateField from '@/components/Forms/CertificateField.vue'
  import EmailField from '@/components/Forms/EmailField.vue'
  import NameField from '@/components/Forms/NameField.vue'
  import PaymentTypeFields from '@/components/Forms/PaymentCurrencyFields.vue'
  import PaymentMethodFields from '@/components/Forms/PaymentMethodFields.vue'
  import PaymentDoubleOfferFields from '@/components/Forms/PaymentSpecialOfferFields.vue'
  import PhoneField from '@/components/Forms/PhoneField.vue'
  import PromocodeField from '@/components/Forms/PromocodeField.vue'
  import { paymentMethods } from '@/enums/paymentMethods'
  import { useCartStore } from '@/stores/cart'
  import { useCartCookieFieldsStore } from '@/stores/cartCookieFields'
  import { parseUtm } from '@/utils/utm'

  const props = defineProps({
    pageId: Number,
    activeCampaignId: Number,
    hidePromocode: {
      type: Boolean,
      default: false,
    },
    hideCertificate: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#8444F6',
    },
  })

  const emit = defineEmits(['afterCreatedOrder'])

  const cartStore = useCartStore()
  const cartCookieFieldsStore = useCartCookieFieldsStore()

  const htmlFormPurchase = ref(null)
  const phoneFieldRef = ref()
  const phoneFieldClass = ref('')

  const { name, phone, email } = storeToRefs(cartCookieFieldsStore)

  const agreement = ref(true)

  const promocodeValue = computed({
    get() {
      return cartStore.promocode.value
    },
    set(val) {
      cartStore.setPromocodeValue(val)
    },
  })

  const certificateValue = computed({
    get() {
      return cartStore.certificate.value
    },
    set(val) {
      cartStore.setCertificateValue(val)
    },
  })

  const isDisabled = computed(() => !agreement.value || cartStore.isPurchaseLoading)

  function changeClassPhoneFieldAfterValidation(val) {
    if (val === true) {
      phoneFieldClass.value = ''
    }
  }

  async function formSubmit() {
    // Check phone if it's not certificate
    // if (cartStore.paymentMethod !== paymentMethods.certificate) {
    // run validation phone
    phoneFieldRef.value.$refs.telInputRef.onInput()

    if (cartStore.phoneIsValid !== true) {
      phoneFieldClass.value = 'shake invalid'
      setTimeout(() => {
        phoneFieldClass.value = 'invalid'
      }, 500)

      phoneFieldRef.value.$refs.telInputRef.focus()

      return
    }

    phoneFieldClass.value = ''
    // }

    if (htmlFormPurchase.value.checkValidity()) {
      // Todo: add ga4 ecommerce events
      event('submit_form_buy', {
        category: 'Action-To-Buy',
        action: 'submit',
        value: cartStore.totalCurrentCurrency,
        label: 'Submit Form For Buy',
        campaign_id: `${props.activeCampaignId}`,
        page_id: `${props.pageId}`,
        product_id: `${cartStore.formattedCart[0].id}`,
        product_name: cartStore.formattedCart[0].name,
        offer_type: cartStore.offerType,
        currency: cartStore.currency,
        price: cartStore.totalCurrentCurrency,
      })

      const formRequest = {
        agreement: agreement.value,
      }

      formRequest.customer = {
        name: name.value,
        phone: phone.value,
        email: email.value,
      }

      if (cartStore.paymentMethod !== paymentMethods.certificate) {
        formRequest.promocode = promocodeValue.value
        formRequest.currency = cartStore.currency
      }

      formRequest.page_id = props.pageId
      formRequest.paymentProvider = cartStore.paymentMethod
      formRequest.offerType = cartStore.offerType
      formRequest.campaign_id = props.activeCampaignId
      formRequest.product = cartStore.formattedCart[0].id
      formRequest.certificate = cartStore.certificate.value

      const queryString = window.location.search
      parseUtm(queryString, formRequest)
      // Todo: ab_field

      cartStore.enablePurchaseLoading()
      cartStore
        .purchase(formRequest)
        .then(async (response) => {
          // Todo: fbq from request

          emit('afterCreatedOrder', {
            paymentProvider: formRequest.paymentProvider,
            data: response.data,
          })
        })
        .catch((err) => {
          console.log('err', err)
          // Todo: error modal
          alert('Упс.., сталася помилка, спробуйте ще раз')
        })
        .finally(() => {
          cartStore.disablePurchaseLoading()
        })
    }
  }
</script>

<template>
  <form
    ref="htmlFormPurchase"
    autocomplete="off"
    @submit.prevent="formSubmit">
    <PaymentDoubleOfferFields
      v-if="cartStore.isCanApplySpecialOffer"
      :color="props.color" />

    <NameField v-model="name" />
    <PhoneField
      ref="phoneFieldRef"
      v-model="phone"
      :class="phoneFieldClass"
      @is-validated="changeClassPhoneFieldAfterValidation" />
    <EmailField v-model="email" />
    <PromocodeField
      v-if="cartStore.isCanApplyPromocode && !hidePromocode && cartStore.paymentMethod === paymentMethods.bank"
      :campaign-id="activeCampaignId"
      v-model="promocodeValue"
      :color="props.color" />

    <PaymentTypeFields :color="props.color" />
    <PaymentMethodFields
      v-if="cartStore.isCanApplyCertificate"
      :color="props.color"
      :hide-certificate="hideCertificate" />

    <CertificateField
      v-if="
        cartStore.isCanApplyCertificate && !hideCertificate && cartStore.paymentMethod === paymentMethods.certificate
      "
      v-model="certificateValue"
      :campaign-id="activeCampaignId"
      :color="props.color" />

    <div
      v-show="cartStore.paymentMethod === paymentMethods.bank"
      class="cart-total"
      :class="{ sale: cartStore.discountIsApply }">
      <div class="cart-total--label"> Сума: </div>
      <div
        class="cart-total--value"
        :class="{ sale: cartStore.discountIsApply }"
        ><span>{{ cartStore.totalCurrentCurrency }} {{ cartStore.currencySymbol }}</span></div
      >
      <div
        v-if="cartStore.discountIsApply"
        class="cart-old--value"
        ><span>{{ cartStore.originalPrice }} {{ cartStore.currencySymbol }}</span></div
      >
    </div>

    <AgreementField v-model="agreement" />

    <div class="cart-submit">
      <button
        :style="{ background: props.color }"
        type="submit"
        class="cart-submit--btn"
        :disabled="isDisabled">
        <PulseLoader
          style="padding-right: 10px"
          size="12px"
          :loading="cartStore.isPurchaseLoading"
          color="#fff" />
        Оплатити
      </button>
    </div>
  </form>
</template>

<style lang="scss">
  .cart-submit--btn {
    &:disabled {
      opacity: 50%;
    }
  }

  // Todo: sale
  .cart-total.sale {
    padding-top: 0px;
  }

  .cart-total--value.sale {
    color: #f84147;
  }

  .cart-old--value {
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;
    color: #999;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;

    &:before {
      content: '';
      position: absolute;
      left: -1px;
      right: -1px;
      top: 50%;
      height: 2px;
      transform: translateY(-50%);
      background-color: #999;
    }
  }
</style>
